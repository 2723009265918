import * as React from "react";
import PropTypes from "prop-types";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Helper from '../../lib/helper';
import { 
  Button,
  Divider,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import useCommonStyles from "../../assets/style/commonStyle";
import clsx from 'clsx';


const UIToolbar = (props) => {
  const {t} = useTranslation('translation');
  const commonClasses = useCommonStyles();

  return (
    <div className={clsx(commonClasses.flexColumn, props.className)} style={{...props.style}}>
      <Toolbar>
        {
          props.showIcon && Helper.isNotNullAndUndefined(props.iconComp) &&
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            {props.iconComp}
          </IconButton>
        }
        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
          {props.title}
        </Typography>
        {
          !isMobile && Helper.isNotNullAndUndefined(props.customCtrl) && props.customCtrl
        }
        {
          !isMobile && props.showCloseButton &&
          <>
            {
              Helper.isNotNullAndUndefined(props.closeButtonComp) ?
              props.closeButtonComp
              :
              <Button color="inherit" variant='outlined' edge='end'
                startIcon={<MUIIcons.Close />}
                onClick={_ => { props.onClose && props.onClose()}}
              >
                {t('toolbar.button.close')}
              </Button>
            }
          </>
        }
      </Toolbar>
      {
        <div className={commonClasses.flexRow} style={{justifyContent: 'flex-end', margin: 6}}>
          {
            isMobile && Helper.isNotNullAndUndefined(props.customCtrl) && props.customCtrl
          }
          {
            isMobile && props.showCloseButton &&
            <>
              {
                Helper.isNotNullAndUndefined(props.closeButtonComp) ?
                props.closeButtonComp
                :
                <IconButton className={commonClasses.toolbarActionButtonMobile} 
                  onClick={_ => { props.onClose && props.onClose()}}
                >
                  <MUIIcons.Close />
                </IconButton>
              }
            </>
          }
        </div>
      }
      {
        props.showDivider &&
        <Divider />
      }
    </div>
  );
}

UIToolbar.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  showIcon: PropTypes.bool,
  iconComp: PropTypes.element,
  showCloseButton: PropTypes.bool,
  closeButtonComp: PropTypes.element,
  onClose: PropTypes.func,
  showDivider: PropTypes.bool,
  customCtrl: PropTypes.element
}

UIToolbar.defaultProps = {
  title: '',
  className: '',
  style: {},
  showIcon: false,
  iconComp: null,
  showCloseButton: true,
  closeButtonComp: null,
  showDivider: true,
  customCtrl: null
}

export default UIToolbar;

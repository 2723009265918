import * as React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { Tooltip, Zoom, Typography } from "@material-ui/core";
import useCommonStyles from '../../assets/style/commonStyle';
import Helper from "../../lib/helper";

const FieldValue = (props) => {
  const commonClasses = useCommonStyles();

  return(
    <fieldset className={clsx(commonClasses.componentFieldValue, props.containerClassName) } style={props.containerStyle}>
      {/* Use -webkit-fit-content to fix Safari not able to display the fieldset top border */}
      <legend style={{width: '-webkit-fit-content'}}>{props.label}</legend>
      {
        Helper.stringHasValue(props.tooltip) ?
        <Tooltip
          TransitionComponent={Zoom} 
          arrow
          disableFocusListener
          enterTouchDelay={0}
          enterDelay={props.tooltipDelay}
          placement={'bottom'}
          title={
            <>
              <Typography color="inherit">{props.tooltip}</Typography>
            </>
          }
        >
          <div className={props.valueClassName} style={props.valueStyle}>
            {props.value}
          </div>
        </Tooltip>
        :
        <div className={props.valueClassName} style={props.valueStyle}>
          {props.value}
        </div>
      }
    </fieldset>
  );
}

FieldValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  valueStyle: PropTypes.object,
  valueClassName: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipDelay: PropTypes.number
}

FieldValue.defaultProps = {
  tooltipDelay: 2000
}

export default FieldValue;

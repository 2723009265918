import * as React from "react";
import { graphql, navigate } from "gatsby";
import { connect } from 'react-redux';
import Toolbar from '../components/Toolbar';
import FieldValue from "../components/FieldValue";
import DropDownList from "../components/DropDownList";
import HelpScreen from "../components/HelpScreen";
import Image from "../components/Image";
import Layout from "../components/layout";
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { 
  Avatar, Paper, Button, IconButton, useMediaQuery,
  TextField, Snackbar, Dialog, DialogTitle, DialogContent,
  DialogActions, Slide, Typography, Tooltip, Zoom
} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import MuiMarkdown from 'mui-markdown';
import MuiAlert from '@material-ui/lab/Alert';
import { isMobile } from 'react-device-detect';
import * as MUIIcons from '@material-ui/icons';

import clsx from 'clsx';
import useCommonStyles from '../assets/style/commonStyle';
import { osName } from 'react-device-detect';
import * as loggingActions from '../redux/actions/loggingActions';
import * as sessionActions from '../redux/actions/sessionActions';
import * as uiActions from '../redux/actions/uiActions';
import Helper from "../lib/helper";
import UIHelper from "../lib/uiHelper";
import Api from '../lib/api';

const userValidationInitState = {
  avatar: {error: false, touched: false, message: ''},
  description: {error: false, touched: false, message: ''},
  email: {error: false, touched: false, message: ''},
  first_name: {error: false, touched: false, message: ''},
  last_name: {error: false, touched: false, message: ''},
  language: {error: false, touched: false, message: ''},
  location: {error: false, touched: false, message: ''},
  role: {error: false, touched: false, message: ''},
  status: {error: false, touched: false, message: ''},
  theme: {error: false, touched: false, message: ''},
  title: {error: false, touched: false, message: ''}
};

const pwdResetValidationInitState = {
  newPassword: {error: false, touched: false, message: ''},
  confirmPassword: {error: false, touched: false, message: ''}
};

const disableTFAValidationInitState = {
  code: {error: false, touched: false, message: ''}
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ZoomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const isBrowser = typeof window !== 'undefined';

const ProfilePage = (props) => {
  const {t} = useTranslation('profile');
  const {changeLanguage} = useI18next();
  const commonClasses = useCommonStyles();
  // const theme = useTheme();
  const locale = props.ui.get('lang');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const displayMode = props.ui.get('displayMode');
  const displayModeToUse = displayMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : displayMode;
  const auth = Helper.getStateAsJSObj(props.session, 'auth', null);
  const sysCodes = Helper.getStateAsJSObj(props.system, 'setting.data.codes', []);
  const sysRoles = Helper.getStateAsJSObj(props.system, 'setting.data.roles', []);
  const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  const loginSecurityConfig = sysConfig.find(item => item.category === 'authentication' && item.identifier === 'login.security');
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  const appDetail = Helper.getStateAsJSObj(props.system, 'setting.data.application', null);

  const [busy, setBusy] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [userFormMode, setUserFormMode] = React.useState('view');
  const myProfileFromRef = React.useRef(null);
  const myProfile = Helper.getStateAsJSObj(props.session, 'profile.data', null);
  const [myProfileAvatar, setMyProfileAvatar] = React.useState(myProfile?.avatar);
  const [userValidation, setUserValidation] = React.useState(userValidationInitState);
  const userFields = [
    {field: 'avatar', required: false, regExp: "", type: 'string', label: 'userform.avatar'},
    {field: 'description', required: false, regExp: '', type: 'string', label: 'userform.description'},
    {field: 'email', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'userform.email'},
    {field: 'first_name', required: true, regExp: '', type: 'string', label: 'userform.first_name'},
    {field: 'last_name', required: false, regExp: '', type: 'string', label: 'userform.last_name'},
    {field: 'language', required: false, regExp: '', type: 'string', label: 'userform.language'},
    {field: 'location', required: false, regExp: '', type: 'string', label: 'userform.location'},
    {field: 'theme', required: false, regExp: '', type: 'string', label: 'userform.theme'},
    {field: 'title', required: false, regExp: '', type: 'string', label: 'userform.title'}
  ];

  const [updateStatus, setUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed, 3 - Email not unique
  const [showResetPwd, setShowResetPwd] = React.useState(false);
  const [resetPwdSuccess, setResetPwdSuccess] = React.useState(false);
  const [resetPwdError, setResetPwdError] = React.useState('');
  const [showDisableTFA, setShowDisableTFA] = React.useState(false);
  const [disableTFASuccess, setDisableTFASuccess] = React.useState(false);
  const [disableTFAError, setDisableTFAError] = React.useState('');

  const pwdResetFormRef = React.useRef(null);
  const [pwdResetValidation, setPwdResetValidation] = React.useState(pwdResetValidationInitState);

  let passwordPolicy = Helper.carefullyGetValue(appDetail, ['passwordPolicy'], null);
  let regex = Helper.carefullyGetValue(passwordPolicy, ['regex'], '');
  const pwdResetFields = [
    {field: 'newPassword', required: true, regExp: regex, type: 'string', label: 'field.newpassword'},
    {field: 'confirmPassword', required: true, regExp: regex, type: 'string', label: 'field.confirmpassword'}
  ];

  let policyLabelText ='';
  let policyLabel = Helper.carefullyGetValue(passwordPolicy, ['label'], []);
  if(Helper.arrayHasItem(policyLabel)) {
    let policyLabelLang = policyLabel.find(item => item.locale === locale);
    if(policyLabelLang) {
      policyLabelText = `${t('label.passwordplicy.note')}${policyLabelLang.text}`;
    }
  }

  const disableTFAFormRef = React.useRef(null);
  const [disableTFAValidation, setDisableTFAValidation] = React.useState(disableTFAValidationInitState);
  const disableTFAFields = [
    {field: 'code', required: true, regExp: '', type: 'string', label: 'field.tfacode'}
  ];

  // Force profile reload when the page is loaded
  React.useEffect(() => {
    props.dispatch(
      sessionActions.profileGet((success, result) => {
        if(!success) {
          if(logEnabled) {
            result.message += ' - systemActions.profileGet Error @ /profile.js';
            props.dispatch(
              loggingActions.loggingNew(
                Helper.populateLoggingDetail(result, {
                  session: props.session.toJS()
                })
              )
            );
          }
    
          consoleLogEnabled && console.error('systemActions.profileGet Error @ /profile.js: ', result);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // Use to register the keydown event
  React.useEffect(() => {
    const onKeyDown = (event) => {
      // console.log('onKeyDown: ', event);
      let hasModifierKey = osName === 'Mac OS' ? event.metaKey : event.ctrlKey;
      switch (event.key.toLowerCase()) {
        case 'f1':
          // Show context help screen
          setShowHelp(value => !value);
          event.preventDefault();
          break;

        case 'e': // Ctrl+E for Windows, Cmd+E for Mac, to enter edit mode
          if(!showHelp && Helper.isNotNullAndUndefined(myProfile) && userFormMode === 'view' && hasModifierKey) {
            editUser();
            event.preventDefault();
          }
          break;

        case 'p': // Ctrl+P for Windows, Cmd+P for Mac, to reset password
          if(!showHelp && Helper.isNotNullAndUndefined(myProfile) && userFormMode === 'view' && hasModifierKey) {
            setShowResetPwd(true);
            event.preventDefault();
          }
          break;

        case '2':
          if(!showHelp && Helper.isNotNullAndUndefined(myProfile) && userFormMode === 'view' && hasModifierKey) {
            showDisableTFADialog();
            event.preventDefault();
          }
          break;
        
        case 'escape': // Esc key to cancel edit
          if(!showHelp && Helper.isNotNullAndUndefined(myProfile) && userFormMode === 'edit') {
            editUserCancel();
            event.preventDefault();
          }
          break;

        case 's': // Ctrl+S for Windows, Cmd+S for Mac, to save the changes
          if(!showHelp && Helper.isNotNullAndUndefined(myProfile) && userFormMode === 'edit' && hasModifierKey) {
            updateUser();
            event.preventDefault();
          }
          break;

        default:
          break;
      }
      
    };

    if(isBrowser) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if(isBrowser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile, userFormMode, showHelp]); // Place the myProfile, userFormMode in the depenency so that when ever the these two state changed, the event listender will get the latest states.


  const userFormUI = () => {
    return(
    <form ref={myProfileFromRef} className={commonClasses.flexColumn} onSubmit={(event) => {
        updateUser();
        event.preventDefault();
      }}
    >
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 2, minWidth: 300}} label={t('userform.email')} value={Helper.carefullyGetValue(myProfile, ['email'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.first_name')} value={Helper.carefullyGetValue(myProfile, ['first_name'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.last_name')} value={Helper.carefullyGetValue(myProfile, ['last_name'], '')} />
          </>
          :
          <>
            <TextField id="email" label={t('userform.email')} 
              style={{flex: 2, minWidth: 300, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(myProfile, ['email'], '')} 
              error={userValidation.email.error}
              helperText={userValidation.email.message}
              onBlur={(event) => Helper.validateFormField('email', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="first_name" label={t('userform.first_name')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(myProfile, ['first_name'], '')} 
              error={userValidation.first_name.error}
              helperText={userValidation.first_name.message}
              onBlur={(event) => Helper.validateFormField('first_name', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="last_name" label={t('userform.last_name')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(myProfile, ['last_name'], '')} 
              error={userValidation.last_name.error}
              helperText={userValidation.last_name.message}
              onBlur={(event) => Helper.validateFormField('last_name', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
          </>
        }
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 2, minWidth: 300}} label={t('userform.title')} value={Helper.carefullyGetValue(myProfile, ['title'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.location')} value={Helper.carefullyGetValue(myProfile, ['location'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.language')} value={UIHelper.getCodeText(myProfile?.language, sysCodes.filter(item => item.category === 'ui.language'))} />
          </>
          :
          <>
            <TextField id="title" label={t('userform.title')} 
              style={{flex: 2, minWidth: 300, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(myProfile, ['title'], '')} 
              error={userValidation.title.error}
              helperText={userValidation.title.message}
              onBlur={(event) => Helper.validateFormField('title', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="location" label={t('userform.location')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(myProfile, ['location'], '')} 
              error={userValidation.location.error}
              helperText={userValidation.location.message}
              onBlur={(event) => Helper.validateFormField('location', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <DropDownList id="language" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.language')} 
              defaultValue={Helper.carefullyGetValue(myProfile, ['language'], '')}
              options={sysCodes.filter(item => item.category === 'ui.language')}
              error={userValidation.language.error}
              helperText={userValidation.language.message}
              // onChange={event => Helper.onFormFieldChanged('language', event.target.value, myProfile, setMyProfile)}
            />
            
          </>
        }
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.theme')} value={UIHelper.getCodeText(myProfile?.theme, sysCodes.filter(item => item.category === 'ui.theme'))} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.role')} value={UIHelper.getRoleText(myProfile?.role, sysRoles, locale)} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.status')} value={UIHelper.getCodeText(myProfile?.status, sysCodes.filter(item => item.category === 'user.status'))} />
          </>
          :
          <>
            <DropDownList id="theme" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.theme')} 
              defaultValue={Helper.carefullyGetValue(myProfile, ['theme'], '')}
              options={sysCodes.filter(item => item.category === 'ui.theme')}
              error={userValidation.theme.error}
              helperText={userValidation.theme.message}
              //onChange={event => Helper.onFormFieldChanged('theme', event.target.value, myProfile, setMyProfile)}
            />
            <DropDownList id="role" 
              variant="outlined"  
              disabled={true}
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.role')} 
              defaultValue={Helper.carefullyGetValue(myProfile, ['role'], '')}
              options={
                sysRoles
                .sort((a, b) => (a.value?.level > b.value?.level ? -1 : (a.value?.level < b.value?.level ? 1 : 0)))
                .map(item => ({code: item.role, text: UIHelper.getRoleText(item.role, sysRoles, locale)}))
              }
              error={userValidation.role.error}
              helperText={userValidation.role.message}
              // onChange={event => Helper.onFormFieldChanged('role', event.target.value, myProfile, setMyProfile)}
            />
            <DropDownList id="status" 
              variant="outlined"  
              disabled={true}
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.status')} 
              defaultValue={Helper.carefullyGetValue(myProfile, ['status'], '')}
              options={sysCodes.filter(item => item.category === 'user.status')}
              error={userValidation.status.error}
              helperText={userValidation.status.message}
              //onChange={event => Helper.onFormFieldChanged('status', event.target.value, myProfile, setMyProfile)}
            />
          </>
        }
      </div>
      {
        !isMobile && userFormMode === 'view' && 
        <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)} style={{marginTop: 20}}>
          <Button variant="outlined" color='secondary' startIcon={<MUIIcons.VpnKey />} onClick={showPasswordReset}>
            {t('button.resetpwd')}
          </Button>
          {
            loginSecurityConfig?.value?.enable_tfa && 
            <Button variant="outlined" color='secondary' startIcon={<MUIIcons.VerifiedUser />} 
              disabled={!loginSecurityConfig?.value?.user_allow_disable_tfa || !Helper.stringHasValue(myProfile?.tfa_secret)}
              onClick={showDisableTFADialog}  
            >
              {t(`button.${Helper.stringHasValue(myProfile?.tfa_secret) ? 'disabletfa' : 'enabletfa'}`)}
            </Button>
          }
        </div>
      }
    </form>);
  }

  const userFormPhoto = <div className={clsx(commonClasses.flexRow, commonClasses.defaultPadding)} style={isMobile ? {position: 'relative', justifyContent: 'center'} : {position: 'relative', flex: 2, justifyContent: 'center'}}>
    {
      userFormMode === 'view' && Helper.stringHasValue(myProfile?.avatar) &&
      <Avatar alt="" className={commonClasses.avatar} src={`${process.env.GATSBY_API_URL}assets/${Helper.carefullyGetValue(myProfile, ['avatar'], '')}?access_token=${auth.data?.access_token}&width=300&height=300&fit=cover&ts=${new Date().getTime()}`} />
    }
    {
      userFormMode === 'view' && !Helper.stringHasValue(myProfile?.avatar) &&
      <MUIIcons.AccountCircle className={commonClasses.avatar} />
    }
    {
      (userFormMode === 'edit' && Helper.isNotNullAndUndefined(myProfileAvatar)) &&
      <>
        {
          typeof myProfileAvatar === 'string' ?
          <Avatar alt="" className={commonClasses.avatar} src={`${process.env.GATSBY_API_URL}assets/${myProfileAvatar}?access_token=${auth.data?.access_token}&width=300&height=300&fit=cover&ts=${new Date().getTime()}`} />
          :
          <div className={commonClasses.avatar} style={{overflow: 'clip', borderRadius: isMobile ? 125 : 150}}>
            <Image width={isMobile ? 250 : 300} height={isMobile ? 250 : 300} 
              objectFit={'fill'}
              src={myProfileAvatar} 
            />
          </div>
        }
      </>
    }
    {
      (userFormMode === 'edit' && !Helper.isNotNullAndUndefined(myProfileAvatar)) &&
      <DropzoneArea
        filesLimit={1}
        //clearOnUnmount={false}
        classes={{
          root: commonClasses.avatarWithBorder
        }}
        acceptedFiles={['image/*']}
        dropzoneText={t('userform.avatar.dropzone')}
        maxFileSize={10485760} // 10mb
        showPreviews={false}
        showPreviewsInDropzone={false}
        onChange={files => {
          setMyProfileAvatar(Helper.arrayHasItem(files) ? files[0] : null)
        }}
        initialFiles={null}
      />
    }
    {
      userFormMode === 'edit' && Helper.isNotNullAndUndefined(myProfileAvatar) &&
      <IconButton aria-label="delete" 
        style={{position: 'absolute', right: 10, top: 10, backgroundColor: '#fff3', width: 50, height: 50, borderRadius: 25}}
        onClick={() => {
          setMyProfileAvatar(null);
        }}
      >
        <MUIIcons.Delete />
      </IconButton>
    }
  </div>

  const editUser = () => {
    setUserFormMode('edit');
    setMyProfileAvatar(myProfile.avatar);
    setUserValidation(userValidationInitState);
  }

  const editUserCancel = () => {
    setUserFormMode('view');
    setMyProfileAvatar(null);
    setUserValidation(userValidationInitState);
    // setMyProfile(Helper.getStateAsJSObj(props.session, 'profile.data', null));
  }

  const updateUser = async () => {
    setUpdateStatus(0);
    try {
      let valid = Helper.validateFormRef(myProfileFromRef, userFields, userValidation, setUserValidation, t);
      if(valid) {
        setBusy(true);
        let formData = Helper.deepCopy(myProfile);

        // Only upload the photo if the myProfileAvatar is not null and is an file object
        if(Helper.isNotNullAndUndefined(myProfileAvatar) && typeof myProfileAvatar !== 'string') {
          let avatar = await Api.fileSet(formData.avatar, myProfileAvatar);
          if(Helper.isNotNullAndUndefined(avatar)) {
            formData.avatar = avatar.id;
          }
        }

        // If myProfileAvatar is null and myProfile.avatar have value, this mean the user is 
        // removing the profile photo.
        if(!Helper.isNotNullAndUndefined(myProfileAvatar) && Helper.stringHasValue(formData.avatar)) {
          await Api.fileDelete(formData.avatar);
          formData.avatar = null;
        }

        // Update the formData var with the actual data in the from
        userFields.forEach(item => {
          if(Helper.isNotNullAndUndefined(myProfileFromRef.current.elements[item.field])) {
            formData[item.field] = Helper.carefullyGetValue(myProfileFromRef, `current.elements.${item.field}.value`.split('.'), '');
          }
        });

        props.dispatch(
          sessionActions.profileUpdate(formData, (success, result) => {
            setBusy(false);
            if(success) {
              setUserFormMode('view');
              setUpdateStatus(1);
              setMyProfileAvatar(null);

              if(Helper.stringHasValue(result.language) && result.language !== locale) {
                props.dispatch(
                  uiActions.setLanguage(result.language)
                );
                changeLanguage(result.language);
              }

              if(Helper.stringHasValue(result.theme) && result.theme !== displayModeToUse) {
                props.dispatch(
                  uiActions.setDisplayMode(result.theme)
                );
              }
            }
            else {
              if(result && Helper.arrayHasItem(result.errors)) {
                // Detect if is email not unique error
                let notUnique = result.errors.find(item => item.extensions?.code === 'RECORD_NOT_UNIQUE');
                if(Helper.isNotNullAndUndefined(notUnique)) {
                  setUpdateStatus(3);  
                }
                else {
                  setUpdateStatus(2);
                }
              }
              else
                setUpdateStatus(2);

              if(logEnabled) {
                result.message += ' - systemActions.profileUpdate Error @ /profile.js'
                
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('profileUpdate Error: ', result);
            }
          })
        );
      }
    } catch (error) {
      setBusy(false);
      setUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - systemActions.profileUpdate Error @ /profile.js'
        
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('profileUpdate Error: ', error);
    }
  }

  const toolbarActionButtons = () => {
    return(
      isMobile ?
      <div className={commonClasses.flexFullRow} style={{gap: 10, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end'}}>
        {
          userFormMode === 'view' ?
          <>
            <div className={commonClasses.flexRow} style={{flex: 1, gap: 10}}>
              <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={showPasswordReset}>
                <MUIIcons.VpnKey />
              </IconButton>
              {
                loginSecurityConfig?.value?.enable_tfa &&
                <IconButton 
                  className={commonClasses.toolbarActionButtonMobile} 
                  disabled={!loginSecurityConfig?.value?.user_allow_disable_tfa || !Helper.stringHasValue(myProfile?.tfa_secret)}
                  onClick={showDisableTFADialog}>
                  <MUIIcons.VerifiedUser />
                </IconButton>
              }
            </div>
            
            <div className={commonClasses.flexRow} style={{flex: 2, gap: 10, justifyContent: 'flex-end'}}>
              <IconButton className={commonClasses.toolbarActionSecondaryButtonMobile} onClick={_ => editUser()}>
                <MUIIcons.EditRounded />
              </IconButton>
            </div>
          </>
          :
          <>
            <IconButton className={commonClasses.toolbarActionPrimaryButtonMobile} onClick={_ => updateUser()}>
              <MUIIcons.Save />
            </IconButton>
            <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={_ => editUserCancel()}>
              <MUIIcons.Cancel />
            </IconButton>
          </>
        }
      </div>
      :
      <div className={commonClasses.flexRow} style={{gap: 10, marginRight: 10, alignItems: 'center'}}>
        {
          userFormMode === 'view' ?
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t(`tooltip.${userFormMode}.${loginSecurityConfig?.value?.enable_tfa ? 'tfa_enabled' : 'tfa_disabled'}`).replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='secondary' startIcon={<MUIIcons.EditRounded />} onClick={_ => editUser()}>
              {t('button.edit')}
            </Button>
          </>
          :
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t(`tooltip.${userFormMode}`).replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            <Button variant='outlined' color='primary' startIcon={<MUIIcons.Save />} onClick={_ => updateUser()}>
              {t('button.save')}
            </Button>
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Cancel />} onClick={_ => editUserCancel()}>
              {t('button.cancel')}
            </Button>
          </>
        }
      </div>
    );
  }

  const showPasswordReset = () => {
    pwdResetFormRef?.current && pwdResetFormRef.current.reset();
    setPwdResetValidation(pwdResetValidationInitState);
    setResetPwdError('');
    setShowResetPwd(true);
  }

  const cancelPasswordReset = () => {
    pwdResetFormRef?.current && pwdResetFormRef.current.reset();
    setPwdResetValidation(pwdResetValidationInitState);
    setResetPwdError('');
    setShowResetPwd(false);
  }

  const passwordReset = () => {
    setResetPwdError('');
    setResetPwdSuccess(false);
    let newPassword = Helper.carefullyGetValue(pwdResetFormRef, 'current.elements.newPassword.value'.split('.'), '');
    let confirmPassword = Helper.carefullyGetValue(pwdResetFormRef, 'current.elements.confirmPassword.value'.split('.'), '');

    let valid = Helper.validateFormRef(
      pwdResetFormRef, pwdResetFields, pwdResetValidation, 
      setPwdResetValidation, t,
      _ => {
        let isValid = true;
        if(newPassword !== confirmPassword) {
          isValid = false;
          setResetPwdError(t('error.pwdNotMatch.message'));
        }
        return isValid;
      });
    
    if(valid) {
      setBusy(true);

      props.dispatch(
        sessionActions.profileSetPwd(newPassword,
          (success, result) => {
            setBusy(false);

            if(success) {
              setResetPwdSuccess(true);
              setShowResetPwd(false);
            }
            else {
              setResetPwdError(t('error.resetpwd.failed'));
              if(logEnabled) {
                result.message += ' - sessionActions.profileUpdate Error @ /profile.js';
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
        
              consoleLogEnabled && console.error('sessionActions.profileUpdate Error @ /profile.js: ', result);
            }
          }
        )
      );
    }
  }

  const showDisableTFADialog = () => {
    if(Helper.stringHasValue(myProfile?.tfa_secret)) {
      disableTFAFormRef?.current && disableTFAFormRef.current.reset();
      setDisableTFAValidation(disableTFAValidationInitState);
      setDisableTFAError('');
      setShowDisableTFA(true);
    }
    else {
      navigate('/tfasetup');
    }
  }

  const cancelDisableTFADialog = () => {
    disableTFAFormRef?.current && disableTFAFormRef.current.reset();
    setDisableTFAValidation(disableTFAValidationInitState);
    setDisableTFAError('');
    setShowDisableTFA(false);
  }

  const disableTFA= () => {
    setDisableTFAError('');
    setDisableTFASuccess(false);
    let valid = Helper.validateFormRef(
      disableTFAFormRef, disableTFAFields, disableTFAValidation, 
      setDisableTFAValidation, t);
    
    if(valid) {
      setBusy(true);
      let code = Helper.carefullyGetValue(disableTFAFormRef, 'current.elements.code.value'.split('.'), '');
      Api.tfaDisable(code)
      .then(_ => {
        setDisableTFASuccess(true);
        setShowDisableTFA(false);

        props.dispatch(
          sessionActions.profileGet((success, result) => {
            if(!success) {
              if(logEnabled) {
                result.message += ' - sessionActions.profileGet Error @ /profile.js';
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('sessionActions.profileGet (Profile) Error: ', result);
            }
            setBusy(false);
          })
        );
      })
      .catch(error => {
        setDisableTFAError(t('error.disabletfa.failed'));
        setBusy(false);

        if(logEnabled) {
          error.message += ' - Api.tfaDisable Error @ /profile.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(error, {
                session: props.session.toJS()
              })
            )
          );
        }
        consoleLogEnabled && console.error('Api.tfaEnable (Profile) Error: ', error);
      });
    }
  }

  return(
    <Layout busy={busy}
      // childrenStyle={{padding: 5, paddingTop: 20}}
    >
      <div className={clsx(commonClasses.flexFullColumn)}>
        <Paper variant={'elevation'} elevation={4}
          className={commonClasses.form}
        >
          <Toolbar title={`${Helper.carefullyGetValue(myProfile, ['first_name'], Helper.carefullyGetValue(myProfile, ['last_name'], ''))}${t('userform.formtitle')}`} 
            className={isMobile ? undefined : commonClasses.toolbarFloatingContainer}
            showIcon={true}
            iconComp={
              Helper.isNotNullAndUndefined(myProfile) ?
              <>
                {
                  Helper.stringHasValue(myProfile.avatar) ?
                  <Avatar alt="" src={`${process.env.GATSBY_API_URL}assets/${myProfile.avatar}?access_token=${auth.data?.access_token}&width=50&height=50&fit=cover`} />
                  :
                  <MUIIcons.AccountCircle />
                }
              </>
              :
              <MUIIcons.AccountCircle />
            }
            showCloseButton={false}
            customCtrl={
              isMobile ?
              null
              :
              <div> 
                {/* Show action button within Toolbar when not in mobile device */}
                {toolbarActionButtons()}
              </div>
            }
          />
          {
            // Show action buttons seperate from toolbar in mobile device
            isMobile &&
            <div className={commonClasses.toolbarFloatingActionButtonContainer}>
              {toolbarActionButtons()}
            </div>
          }
          <div className={clsx(commonClasses.flexColumn, commonClasses.defaultPadding)} style={{marginTop: 6}}>
            {
              isMobile &&
              <>
                {userFormPhoto}
                {userFormUI()}
              </>
            }
            {
              !isMobile &&
              <div className={clsx(commonClasses.flexFullRow)}>
                <div style={{flex: 6}}>
                  {userFormUI()}
                </div>
                {userFormPhoto}
              </div>
            }
          </div>
          
        </Paper>
      </div>

      {/* User Form Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={updateStatus !== 0}
        onClose={_ => setUpdateStatus(0)}
        key={'userUpdateStatusSnackbar'}
      >
        {
          updateStatus !== 0 ?
          <Alert onClose={_ => setUpdateStatus(0)} severity={updateStatus === 1 ? 'success' : 'error'}>
            {t(`userform.updatestatus.${updateStatus === 1 ? 'success' : (updateStatus === 2 ? 'failed' : 'duplicated')}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Password Reset Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={resetPwdSuccess}
        onClose={_ => setResetPwdSuccess(false)}
        key={'userResetPwdSuccessSnackbar'}
      >
        <Alert onClose={_ => setResetPwdSuccess(false)} severity={'success'}>
          {t(`userform.pwdreset.success`)}
        </Alert>
      </Snackbar>
      
      {/* Disable TFA Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={disableTFASuccess}
        onClose={_ => setDisableTFASuccess(false)}
        key={'userDisableTFASuccessSnackbar'}
      >
        <Alert onClose={_ => setDisableTFASuccess(false)} severity={'success'}>
          {t(`userform.disabletfa.success`)}
        </Alert>
      </Snackbar>
      
      {/* Password Reset Dialog */}
      <Dialog
        open={showResetPwd}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancelPasswordReset}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <form ref={pwdResetFormRef} onSubmit={(event) => {
            passwordReset();
            event.preventDefault();
          }}
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <Typography variant="h6">
              {t('dialog.resetpwd.title')}
            </Typography>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={commonClasses.flexColumn}>
            <Typography variant='body1'>
              {t('dialog.resetpwd.desc')}
            </Typography>
            
            <Typography variant='subtitle1' color="secondary" style={{marginBottom: 30}}>
                {policyLabelText}
            </Typography>
            {/* Create a hidden field for username for browser password manager and to prevent DOM warning. */}
            <input id="username" 
              autoComplete='username'
              value={myProfile?.email} 
              disabled={true}
              hidden={true}
            />
            <TextField id="newPassword" label={t('field.newpassword')} 
              style={{marginBottom: 10}}
              variant="outlined" 
              autoComplete='new-password'
              type={'password'}
              error={pwdResetValidation.newPassword.error}
              helperText={pwdResetValidation.newPassword.message}
              onBlur={(event) => Helper.validateFormField('newPassword', event.target.value, pwdResetFields, pwdResetValidation, setPwdResetValidation, t)}
            />
            <TextField id="confirmPassword" label={t('field.confirmpassword')} 
              style={{marginBottom: 10}}
              variant="outlined" 
              autoComplete='new-password'
              type={'password'}
              error={pwdResetValidation.confirmPassword.error}
              helperText={pwdResetValidation.confirmPassword.message}
              onBlur={(event) => Helper.validateFormField('confirmPassword', event.target.value, pwdResetFields, pwdResetValidation, setPwdResetValidation, t)}
            />
            {
              Helper.stringHasValue(resetPwdError) &&
              <Typography variant='subtitle1' color="error">
                {resetPwdError}
              </Typography>
            }
            
          </DialogContent>
          <DialogActions>
            <Button
              onClick={cancelPasswordReset}
            >
              {t('button.cancel')}
            </Button>
            <Button variant={'contained'} type='submit' 
              color={'primary'}
              // If passwordPolicy is not defined, do not allow user to reset password
              disabled={!Helper.isNotNullAndUndefined(appDetail?.passwordPolicy)}
            >
              {t('button.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      
      {/* TFA Disable Dialog */}
      <Dialog
        open={showDisableTFA}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancelDisableTFADialog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <form ref={disableTFAFormRef} onSubmit={(event) => {
            disableTFA();
            event.preventDefault();
          }}
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <Typography variant="h6">
              {t('dialog.disabletfa.title')}
            </Typography>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={commonClasses.flexColumn}>
            <Typography variant='body1' style={{marginBottom: 20}}>
              {t('dialog.disabletfa.desc')}
            </Typography>
            <TextField id="code" label={t('field.tfacode')} 
              style={{marginBottom: 10}}
              variant="outlined" 
              type='number'
              error={disableTFAValidation.code.error}
              helperText={disableTFAValidation.code.message}
              onBlur={(event) => Helper.validateFormField('code', event.target.value, disableTFAFields, disableTFAValidation, setDisableTFAValidation, t)}
            />
            {
              Helper.stringHasValue(disableTFAError) &&
              <Typography variant='subtitle1' color="error">
                {disableTFAError}
              </Typography>
            }
          </DialogContent>
          <DialogActions>
            <Button
              onClick={cancelDisableTFADialog}
            >
              {t('button.cancel')}
            </Button>
            <Button
              color='primary'
              type='submit'
            >
              {t('button.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Help Screen */}
      <HelpScreen
        open={showHelp}
        onClose={_ => setShowHelp(false)}
        title={t('dialog.help.title')}
      >
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.desc')}
        </Typography>
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.functions').replace(/{modifier}/g, UIHelper.getModifierKey())}
        </Typography>
        <MuiMarkdown>
          {t('dialog.help.keys').replace(/{modifier}/g, UIHelper.getModifierKey())}
        </MuiMarkdown>
      </HelpScreen>
    </Layout>
  );
}


export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(ProfilePage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
